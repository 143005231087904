
/* Split the screen in half */
@media (min-width: 1024px) {
  .split {
    height: 100%;
    width: 50%;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    padding: 20px;
  }
}

/* Control the left side */
.left {
  color: white;
  left: 0;
  /* background-color: #0071be; */
  border: 5px solid white;
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./img/ex5.jpg'); */
  background: url('./img/ex5.jpg');
  background-size: contain;
  background-position: center;
}

.left * {
  margin-bottom: 5%;
}

* {
  font-family: "futura";
}

.left h1 {
  font-size: 6vh;
}

.left p {
  font-size: 1.70vh;
}

.left img {
  width: 120px;
}

/* Control the right side */
.right {
  /* color: #0071be; */
  color: white;
  right: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./img/ex4.jpg');
  background-size: cover;
  background-position: center;
  border: 5px solid #0071be;
  padding: 40px;
}

.right h6 {
  font-size: 18px;
  padding-bottom: 1%;
}

.right .CGV {
  text-align: justify;
}

.right .CGV p {
  font-size: 10px;
}

.right .je_soussigne {
    padding-top: 30px;
}

.right .submit {
  padding-top: 30px;
}

/* If you want the content centered horizontally and vertically */
.left .centered {
  display:center; 
  justify-content:center; 
  width:100%; 
  text-align:center;
  padding-top: 10%;
  padding-bottom: auto;
}

.right .centered {
  display:center; 
  justify-content:center; 
  width:100%; 
  text-align:left;
  padding-top: 10%;
  padding-bottom: auto;
}

.right button {
  width: 200px;
}


.spinner {
  -webkit-animation: rotator 1.4s linear infinite;
          animation: rotator 1.4s linear infinite;
}

@-webkit-keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  -webkit-animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
          animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}

@-webkit-keyframes colors {
  0% {
    stroke: #4285f4;
  }
  25% {
    stroke: #de3e35;
  }
  50% {
    stroke: #f7c223;
  }
  75% {
    stroke: #1b9a59;
  }
  100% {
    stroke: #4285f4;
  }
}

@keyframes colors {
  0% {
    stroke: #4285f4;
  }
  25% {
    stroke: #de3e35;
  }
  50% {
    stroke: #f7c223;
  }
  75% {
    stroke: #1b9a59;
  }
  100% {
    stroke: #4285f4;
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}