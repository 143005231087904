.wrapper {
  width: 1920px !important;
  height: 1150px !important;
  overflow: visible !important;
}
.screen {
  display: none;
}

/* .form-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
} */

.i_split {
    height: 100%;
    width: 50%;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    padding: 20px;
  }
  @media all and (max-width: 768px) {
  .wrapper {
    width: 100% !important;
    height: 2000px !important;
    overflow: visible !important;
  }
  .i_split {
    width: 100%;
    position: relative;
  }
  }
  
  .i_left {
    color: white;
    left: 0;
    background-color: #0071be;
    border: 5px solid white;
  }

  .i_left .row {
      padding-top: 5px;
  }
  
  .i_right {
    color: #0071be;
    right: 0;
    background-color: white;
    border: 5px solid #0071be;
    padding: 40px;
  }

  .i_right h6 {
    font-size: 18px;
    padding-bottom: 1%;
  }

  .i_right .CGV {
    text-align: justify;
  }

  .i_right .CGV p {
    font-size: 0.75vh;
  }

  .i_right .je_soussigne {
      padding-top: 30px;
  }

  .i_right .submit {
    padding-top: 30px;
}
  
  .i_centered {
    display:flex; 
    justify-content:center; 
    width:100%; 
    text-align:center;
  }
  
  .i_centered img {
    width: 100px;
    /* border-radius: 50%; */
  }