.wrapper2 {
  width: 1920px !important;
  height: 1150px !important;
  overflow: visible !important;
}

.screen {
  display: none;
}

/* .form-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
} */

/* Split the screen in half */
  .s_split {
    height: 100%;
    width: 50%;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    padding: 10px;
    font-size: 14px;
  }

    @media all and (max-width: 768px) {
      .wrapper2 {
        width: 100% !important;
        height: 2200px !important;
        overflow: visible !important;
      }
        .s_split {
        width: 100%;
        position: relative;
        }
    }
  
  /* Control the left side */
  .s_left {
    color: white;
    left: 0;
    background-color: hotpink;
    border: 5px solid white;
  }

  .s_left img {
    padding-top: 10px;
  }

  .s_left .col {
      padding-top: 5px;
  }

  .s_left .en-tete {
    border: 5px solid white;
  }

  .s_left .en-tete .content {
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .s_left .grid .row {
    border-top: 1px solid white;
    border-left: 1px solid white;
  }
  .s_left .grid .grid-element {
    border-right: 1px solid white;
    display:flex; 
    justify-content:center; 
    align-items: center;
    width:100%; 
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .s_left .grid .grid-end {
    border-bottom: 1px solid white;
  }

  .s_left label {
    padding-right: 10px;
  }
  
  /* Control the right side */
  .s_right {
    color: hotpink;
    right: 0;
    background-color: white;
    border: 5px solid hotpink;
    padding: 40px;
  }

  .s_right .grid .row {
    border-top: 1px solid hotpink;
    border-left: 1px solid hotpink;
  }
  .s_right .grid .grid-element {
    border-right: 1px solid hotpink;
    display:flex; 
    justify-content:center; 
    align-items: center;
    width:100%; 
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .s_right .grid .grid-element-left {
    border-right: 1px solid hotpink;
    display:flex; 
    justify-content:left; 
    align-items: left;
    width:100%; 
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .s_right .grid .grid-end {
    border-bottom: 1px solid hotpink;
  }
  /* fdaff3 */
  /* If you want the content centered horizontally and vertically */
  .s_centered {
    display:flex; 
    justify-content:center; 
    width:100%; 
    text-align:center;
  }
  
  /* Style the image inside the centered container, if needed */
  .s_centered img {
    width: 100%;
    /* border-radius: 50%; */
  }